<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
      <div :key="render_table">
        <div v-if="this.returned_tasks" class="text-h3">You have {{returned_tasks.length}}</div>
        <div v-for="(task,index) in returned_tasks" :key="index">
          <q-list bordered>
            <q-item :href="'/execute-task/'+task.id" clickable v-ripple>
              <q-item-section class="text-h5" :id="index">{{task.id}} - {{task.title}}</q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>Task address: {{task.address}}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section :id="index">Due date: {{task.due_date}}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>{{ task.description }}</q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import {useQuasar} from "quasar";

export default {
  name: 'OpenTasksPage',
  setup(){
    return {
    }
  },
  data(){
    return {
      returned_tasks : null,
      render_table : null
    }
  },
  mounted(){
    this.syncData();
  },
  computed: {
    token: function () {
      return this.$store.getters.token;
    },
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    }
  },
  methods : {
    async syncData(){
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-tasks', {
        headers: {
          'Authorization': 'Bearer ' + this.token
        },
        params: {
          owner_type: 'ManagedByMe'
        }
      })
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_tasks = res.data.data;
            this.render_table = true;
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
