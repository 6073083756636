<template>
  <q-dialog :full-width="true" v-model="modal_shown">
    <q-card class="my-card bg-gray text-gray">
      <q-card-section>
        <q-item v-ripple>
          <q-item-section class="text-h5">{{this.task.reference}} </q-item-section>
          <q-item-section avatar>
            <q-icon color="primary" name="settings">

            </q-icon>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            {{ the_form.type }} : {{ the_form.description}}
          </q-item-section>
        </q-item>
        <div v-for="form_item in the_form.form_items" :key="form_item.id">
          <div v-for="item in form_item.form_answers" :key="item.id">
            <q-item>
              <q-item-section>
                    <q-item-label class="q-mt-md q-mb-md">
                      {{ form_item.label }}
                    </q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                    <div v-if="form_item.type==='Datetime'" class="flex py-4 px-4">
                      <input v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='date'" type="date"/>
                      <input v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='datetime'" type="datetime-local"/>
                      <input v-model="item.answers" v-if="JSON.parse(form_item.options).timestampType==='time'" type="time"/>
                    </div>
                    <div v-if="form_item.type==='Text'" class="flex  py-4 px-4">
                                  <textarea
                                      v-model="item.answers"
                                      @change="storeForm"
                                      :rows="JSON.parse(form_item.options).lines"
                                      :minlength="JSON.parse(form_item.options).minLength"
                                      :maxlength="JSON.parse(form_item.options).maxLength"
                                  />
                    </div>
                    <div v-if="form_item.type==='Signature'" class="flex py-4 px-4">
                      <input type="hidden" v-model="item.answers"/>
                      <SignatureCard :model-value="item.file"
                                     @saveForm="storeForm()"
                                     @update:extension="extension => handleExtension(extension, form_item)"
                                     @update:files="files => handleFiles(files, form_item)">
                      </SignatureCard>
                    </div>
                    <div v-if="form_item.type==='Number'" class="flex  py-4 px-4">
                      <input
                          @change="storeForm"
                          v-model="item.answers" type="number"
                          :min="JSON.parse(form_item.options).Minimum"
                          :max="JSON.parse(form_item.options).Maximum"
                      />
                    </div>
                    <div v-if="form_item.type==='Choices'" class="flex  py-4 px-4">
                      <select  v-model="item.answers" :multiple="JSON.parse(form_item.options).multiChoice" class="rounded-lg mt-2 mb-2">
                        <option selected>
                          -- Select --
                        </option>
                        <option v-for="choice in JSON.parse(form_item.options).selectable_options" :key="choice" :value="choice">
                          {{ choice }}
                        </option>
                      </select>
              </div>
                    <div v-if="form_item.type==='Boolean'" class="flex py-4 px-4">
                      <select v-model="item.answers" class="rounded-lg mt-2 mb-2">
                        <option selected>
                          -- Select --
                        </option>
                        <option :value="JSON.parse(form_item.options).falseOption">
                          {{ JSON.parse(form_item.options).falseOption }}
                        </option>
                        <option :value="JSON.parse(form_item.options).falseOption">
                          {{ JSON.parse(form_item.options).trueOption }}
                        </option>
                      </select>
                  </div>
                  <div class="q-mt-md q-mb-md" v-if="form_item.type==='Boolean' && JSON.parse(form_item.options).negativeActionValues.hasPhotos">
                    <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                      <DropzoneComponent
                          @update:extension="extension => handleExtension(extension, form_item)"
                          @update:files="files => handleFiles(files, form_item)"
                          :type="(form_item.options).fileType">
                      </DropzoneComponent>
                    </div>
                    <div class="w-1/3" v-if="form_item.file">
                      <img
                          :src="createBlob(form_item)"
                          style="height: 140px; max-width: 150px"
                      >
                    </div>
                  </div>
                  <div v-if="form_item.type==='File'" class="flex  py-4 px-4">
                  <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                    <DropzoneComponent
                        @update:extension="extension => handleExtension(extension, form_item)"
                        @update:files="files => handleFiles(files, form_item)"
                        :type="(form_item.options).fileType">
                    </DropzoneComponent>
                  </div>
                  <div class="w-1/3" v-if="form_item.file">
                    <img
                        :src="createBlob(form_item)"
                        style="height: 140px; max-width: 150px"
                    >
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </div>
        </div>
        <q-item class="mt-4 mb-4">
            <q-btn @click="storeForm">Store</q-btn>
        </q-item>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import SignatureCard from "@/components/SignatureCard";
import axios  from "axios";
import {Notify} from "quasar";
import DropzoneComponent from "@/components/DropzoneComponent.vue";
export default {
  name: "ChecklistPage",
  props: [ 'form','showModal','task'],
  emits: ['closeModal'],
  components: {
    DropzoneComponent,
    SignatureCard
  },
  computed:{
    url(){
      return this.$store.getters.url;
    }
  },
  methods:{
    createBlob(item){
      return URL.createObjectURL(item.file);
    },
    handleFiles(files,item){
      item.file = files;
      console.log("Files: ");
      console.log(item);
      console.log("Files: ");
      item.form_answers[0].answers = files;
    },
    handleExtension(extension,item){
      console.log("Extension: ", extension)
      item.file_extension = extension;
    },
    storeForm(){
      let formData = new FormData();
      this.the_form.form_items.forEach((item, index) => {
        if (item.file) {
          let blob = new Blob([item.file], { type: 'image/png' });
          let file_name = JSON.parse(item.options).fileName + '.' + item.file_extension;
          if(item.type==='Boolean'){
             file_name = item.description+'.' + item.file_extension;
          }
          formData.append(`form_items[${index}][id]`, item.id);
          formData.append(`form_items[${index}][file]`,blob , file_name);
          formData.append(`form_items[${index}][form_answers]` , JSON.stringify(item.form_answers));
        }else {
          formData.append(`form_items[${index}][id]`, item.id);
          formData.append(`form_items[${index}][form_answers]`, JSON.stringify(item.form_answers));
        }
      });
      let config = {
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
          'Content-Type': 'multipart/form-data'
        }
      }
        axios.post(this.url+'/api/api-form-filled-store',formData,config)
          .then((res) => {
          if(res.data.type==='success'){
            this.$emit('closeModal');
            Notify.create({
              message: res.data.message,
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done'
            });
          }
        }).catch((e) => {
          console.log(e);
        });
    },
  },
  data(){
    return {
        the_form : this.form,
        modal_shown: this.showModal
    }
  },
  mounted() {
    console.log(this.the_form);
  }
}
</script>

<style scoped>

</style>